<template>
  <!-- 发劵记录 -->
  <div class="box-card">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
      @submit.native.prevent>
      <el-form-item label="停车场：">
        <el-select popper-class="my-select" clearable v-model="queryForm.parking_id" filterable default-first-option
          placeholder="请选择停车场" @change="onSubmit()">
          <el-option v-for="item in getAllParkingList()" :key="item.parking_id" :label="item.parking_name"
            :value="item.parking_id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="车牌号：">
        <el-input maxlength="8" placeholder="请输入车牌号" v-model="queryForm.plate_number" @keyup.enter.native="onSubmit"
          @input="(e) => (queryForm.plate_number = validPlatetoUpperCase(e))"></el-input>
      </el-form-item>
      <el-form-item label="商家名称：">
        <el-input maxlength="30" v-model="queryForm.merchant_name" @keyup.enter.native="onSubmit" placeholder="请输入商家名称"
          @input="(e) => (queryForm.merchant_name = validSpace(e))"></el-input>
      </el-form-item>
      <el-form-item label="发劵时间：">
        <el-date-picker v-model="timeduan" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"
          @blur="onSubmit" type="datetimerange" range-separator="至" start-placeholder="请选择开始时间" end-placeholder="请选择结束时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card title="发劵记录">
      <template slot="heard-right">
      </template>
      <!-- 主要内容 -->
      <div style="text-align:left;">
        <!-- 表格 -->
        <!-- 使用插件生产表格 -->
        <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @size-change="sizeChange"
          @current-change="currentChange">
          <template slot-scope="{row}" slot="create_time">
            {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
          </template>
          <template slot-scope="{row}" slot="menu">
            <lbButton type="succes" icon="chakan" hint="查看" @click="lookCouponRecord(row._id)"></lbButton>
          </template>
        </avue-crud> -->
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe>
          <el-table-column prop="merchant_name" label="商家名称" min-width="110"></el-table-column>
          <el-table-column prop="parking_name" label="停车场"></el-table-column>
          <el-table-column prop="plate_number" label="车牌号" width="140"></el-table-column>
          <el-table-column prop="parking_time" label="停车时长"></el-table-column>
          <el-table-column prop="coupon_name" label="优惠劵"></el-table-column>
          <el-table-column label="发劵时间" width="180">
            <template slot-scope="scope">
              {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <lbButton type="succes" icon="chakan" hint="查看" @click="lookCouponRecord(scope.row._id)"></lbButton>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
        <!-- 查看发券记录 -->
        <lebo-dialog append-to-body title="查看发券记录" :isShow="showCouponRecord" width="70%"
          @close="showCouponRecord = false" footerSlot closeOnClickModal>
          <lookCouponRecord @closeDialog="closeCouponRecord" :id="dialogCouponRecordId" v-if="showCouponRecord">
          </lookCouponRecord>
        </lebo-dialog>
      </div>
    </le-card>

  </div>
</template>
<script>
import { getIssueCouponsPageList, getIssueCouponsDetails } from '@/api/discountCoupon'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import lookCouponRecord from './components/lookCouponRecord/index.vue'
export default {
  components: { lookCouponRecord },
  data () {
    return {
      timeduan: null,
      queryForm: {
        PageSize: 10,
        PageIndex: 1,
        parking_id: '',
        merchant_name: '',
        plate_number: '',
        start_time: '',
        end_time: ''
      },
      total: 0,
      // 表格数据
      tableData: [],
      showCouponRecord: false, // 查看优惠券弹窗
      dialogCouponRecordId: '',
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '商家名称',
            prop: 'merchant_name'
          }, {
            label: '停车场',
            prop: 'parking_name',
            slot: true
          }, {
            label: '车牌号',
            prop: 'plate_number',
            slot: true
          }, {
            label: '停车时长',
            prop: 'parking_time',
            slot: true
          }, {
            label: '优惠劵',
            prop: 'coupon_name',
            slot: true
          }, {
            label: '发劵时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
    }
    this.fngetIssueCouponsPageList()
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName', 'getAllParkingList'])
  },
  watch: {
    'timeduan' (newV, oldV) {
      if (newV) {
        this.queryForm.start_time = newV[0]
        this.queryForm.end_time = newV[1]
      } else {
        this.queryForm.start_time = ''
        this.queryForm.end_time = ''
        this.onSubmit()
      }
    }
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  methods: {
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    async fngetIssueCouponsPageList () {
      const res = await getIssueCouponsPageList(this.queryForm)
      this.tableData = res && res.Code === 200 && res.Data ? res.Data.DataList : []
      this.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fngetIssueCouponsPageList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fngetIssueCouponsPageList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fngetIssueCouponsPageList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`);
      this.queryForm.PageIndex = val
      this.fngetIssueCouponsPageList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fngetIssueCouponsPageList()
    // },
    closeCouponRecord () {
      this.showCouponRecord = false
      this.fngetIssueCouponsPageList()
    },
    lookCouponRecord (id) {
      this.showCouponRecord = true
      this.dialogCouponRecordId = id
    }
  }
}
</script>
<style scoped lang="less">
.box-card {
  margin-top: 20px;
}
/deep/.el-form.query_form.el-form--inline .el-form-item.el-form-item--small .el-form-item__content .el-date-editor{
  width: 350px;
}
</style>
