<template>
    <div class="box-card" style="text-align: left;margin-top: 0;">
      <el-form label-width="120px" class="left_box" v-if="detailForm">
        <div class="title">停车信息</div>
        <el-divider></el-divider>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="车牌号：">{{detailForm.plate_number}}</el-form-item>
            <el-form-item label="入场时间：">{{ $moment(detailForm.go_in_time).format("YYYY-MM-DD HH:mm:ss") }}</el-form-item>
            <el-form-item label="停车时长：">{{detailForm.parking_time ? detailForm.parking_time: '暂未出场'}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="停车场：">{{detailForm.parking_name}}</el-form-item>
            <el-form-item label="出场时间："> {{ $moment(detailForm.go_out_time).format("YYYY-MM-DD HH:mm:ss") }}</el-form-item>
            <el-form-item label="优惠劵：">{{detailForm.coupon_name}}</el-form-item>
          </el-col>
        </el-row>
        <div class="title">商家信息</div>
        <el-divider></el-divider>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="商家号：">{{detailForm.merchant_number}}</el-form-item>
            <el-form-item label="负责人：">{{detailForm.principal}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商家名称：">{{detailForm.merchant_name}}</el-form-item>
            <el-form-item label="手机号：">{{detailForm.phone}}</el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </template>
<script>
import { getIssueCouponsPageList, getIssueCouponsDetails } from '@/api/discountCoupon'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
export default {
  props: ['id'],
  data () {
    return {
      detailForm: {}
    }
  },
  created () {
    this.fngetIssueCouponsDetails()
  },
  mounted () { },
  computed: {},
  watch: {},
  methods: {
    ...mapMutations(['setQueryStatus']),
    // 返回
    goBack () {
      this.setQueryStatus(2)
      this.$router.go(-1)
    },
    async fngetIssueCouponsDetails () {
      const res = await getIssueCouponsDetails({ _id: this.id })
      console.log(res)
      this.detailForm = res && res.Code === 200 ? res.Data : {}
    }
  }
}
</script>
  <style scoped lang="less">
  .title {
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    color: #000;
  }
  .left_box {
    .el-form-item {
      margin-bottom: 0;
    }
  }
  </style>
